//imports
import React, { useState } from "react";
import style from "./css/Signin.module.css";
import { auth, provider, onAuthStateCahnged, signInWithPopup, db } from "../firebase.js";
import { get, push, onValue, set, ref as refDb, update } from "firebase/database";
import Loading from "./Loading.js";
import Toast from "./Toast.js"; // componets
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer.js"
import Drower from "./Drower.js"; //components


function Signin(props){
  // variables and react hooks
  const rs = "₹";
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAgree, setAgree] = useState(true);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] = useState({});
  const now = new Date();
  const date = now.toLocaleDateString();
  const time = now.toLocaleTimeString('en-US');
  const dateAndTime = `${date} ${time}`;
  const location = useLocation();
  const referralParam = new URLSearchParams(location.search);
  const referralCode = referralParam.get("code");
    const [drower, setDrower] = useState(false);
  //
  
  // functions
  const showToast = () => {
    setToast(!toast);
  };
  //
  const goToTAC = () => {
    navigate("/TermsAndConditions");
  };
  //
  const onCheckboxChanged = (e) => {
    setAgree(e.target.checked);
  };
  const signinClicked = async () => {
    if(isAgree){
    setLoading(true);
    try{
    const result = await signInWithPopup(auth, provider);
    const userRef = refDb(db, "user/" + result.user.uid);
    const userData = await get(userRef);
    // create a data for new user 
    if(!userData.exists()){
      // if signup by referral 
    let referral = false;
    if(referralCode){
       await get(refDb(db, "user/" + referralCode)).then((snapshot) => {
          if(snapshot.exists() && snapshot.val().referralCount <= 5){
            referral = true;
            update(refDb(db, "user/" + referralCode), {
            ruppee: snapshot.val().ruppee + 10,
            referralCount: snapshot.val().referralCount + 1,
           });
           push(refDb(db, "user/" + referralCode + "/notification"), {
            msg: "Congratulations! Your referral link has been successfully used, and you’ve earned a reward of ₹10. Keep sharing and earning more!",
            img: "./images/icon.png",
            date: dateAndTime,
          });
          };
        });
    };
    //add user data in database
      await update(userRef, {
      ruppee: referral ? 20 : 10,
      referralCount: 0,
      ec: 0,
      exp: 100,
      stars: 0,
      name: result.user.uid.substring(0, 6).toLowerCase(),
      profilePicture: "/images/user.png",
      levelUpRewards: {
        level5: false,
        level10: false,
        level20: false,
      },
      seasonRewards: {
        silver: false,
        gold: false,
        diamond: false,
      },
      dailyMissons: {
        task1: {
          track: 0,
          date: false,
          claimed: false,
        },
        task2: {
          track: 0,
          date: false,
          claimed: false,
        },
      task3: false,
      },
    });
    push(refDb(db, "user/" + result.user.uid + "/notification"), {
      msg: referral ? "Welcome! You’ve earned ₹10 as a signup bonus and ₹10 from the referral link. Enjoy the rewards!"
      : "Welcome! You’ve successfully signed in and earned ₹10 signup bonus. Start exploring now!",
      img: "./images/icon.png",
      date: dateAndTime,
    });
    };
    setToastData({
        msg: "Login successfull!",
      });
      showToast();
      setTimeout(() => {
         navigate("/");
       }, 3000);
    }catch(e){
      setToastData({
        msg: e.message,
      })
      showToast();
    }finally{
      setLoading(false);
    }
    }else{
      setToastData({
        msg: "please agree App's Terms & Conditions!",
      });
      showToast();
    };
  };
  
  //drowerShow
  const drowerShow = () => {
    setDrower(!drower);
  }
  
  
  return (
    <>
     <div id={style.bg} >
      <div id={style.header}>
       <div className={style.linear}>
       <i id={style.menuBtn} onClick={drowerShow} className="fa-solid fa-bars"></i>
       <img id={style.logo} alt="ezexa.xyz" src="./images/event.png"/>
       </div>
       <div id={style.helpBtn}>
       <i className="fa-solid fa-circle-question"></i>
        Help
       </div>
      </div>
      <div id={style.body}>
       <h1 id={style.heading}>Smart Ways to <br/> Earn <span> Money </span> <br/> Online </h1>
       <p id={style.paragraph}>Play exciting games, complete simple tasks, and earn money without any investment! It’s easy, fun, and rewarding. Just click the button below to get started and receive ₹10 instantly on your first signup!
       </p>
       <button id={style.btn} onClick={signinClicked}>
           <img src="/images/googleicon.png" style={{width: '30px', marginRight: '6px'}} />
           Continue With Google
         </button>
        <img id={style.screenShots} src="./images/ezexaScreenShots.png"/>
        {/*Rating Box (start) */}
        <div id={style.ratingDiv}>
        <h3> RATED BY ONLINE <span>E-ZEXA </span> EARNERS </h3>
         <div id={style.ratingBox}>
          <div id={style.leftDiv}>
           <div>
            <h1>4.0</h1>
           </div>
           <div>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"
               style={{color: "darkgray"}}></i>
           </div>
           <div>
            <p>
             39752 RATINGS
            </p>
           </div>
          </div>
          <div id={style.rightDiv}>
          
           <div className={style.rightDivsLinear}>
            <p>5</p>
            <div className={style.progressDiv}>
             <div id={style.progress5}></div>
            </div>
            <p>84%</p>
           </div>
           
           <div className={style.rightDivsLinear}>
            <p>4</p>
            <div className={style.progressDiv}>
             <div id={style.progress4}></div>
            </div>
            <p>21%</p>
           </div>
           
           <div className={style.rightDivsLinear}>
            <p>3</p>
            <div className={style.progressDiv}>
             <div id={style.progress3}></div>
            </div>
            <p>02%</p>
           </div>
           
           <div className={style.rightDivsLinear}>
            <p>2</p>
            <div className={style.progressDiv}>
             <div id={style.progress2}></div>
            </div>
            <p>03%</p>
           </div>
           
           <div className={style.rightDivsLinear}>
            <p>1</p>
            <div className={style.progressDiv}>
             <div id={style.progress1}></div>
            </div>
            <p>10%</p>
           </div>
           
          </div>
         </div>
        </div>
        {/*Rating Box (end) */}
        
        <Footer />
      </div>
     </div>
     { drower && <Drower drowerShow={drowerShow} goToPage={props.goToPage}/>}
     { loading && <Loading /> }
     { toast && <Toast msg={toastData.msg} toastShow={showToast} color={toastData.color}/> }
     </>
    );
};
export default Signin;