//imports
import React, { useState, useEffect } from "react";
import style from "./App.module.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./Components/Dashboard.js"; //Components
import Splash from "./Components/Splash.js"; //Components
import Signin from "./Components/Signin.js"; //Components
import { auth, db, messaging } from "./firebase.js";
import { getToken } from "firebase/messaging";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { get, ref as refDb } from "firebase/database";
import TermsAndConditions from "./Components/TermsAndConditions.js" //Components
import PrivacyPolicy from "./Components/PrivacyPolicy.js"; //Components
import Rankbadge from "./Components/Rankbadge.js"; //Components
import Confirm from "./Components/Confirm.js"; //components
import Ec from "./Components/Ec.js"; //Components
import Story from "./Components/Story.js"//Components
import Game from "./Components/Game.js"; //Components
import About from "./Components/About.js" //Components
import EStore from "./Components/EStore.js"; //Components
import InDevelopment from "./Components/InDevelopment.js"; //Components



function App() {
  // variables and react hooks
  const [splash, setSplash] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const audio = new Audio("/click.wav");
  const playSound = () => {
    audio.currentTime = 0;
    audio.play();
  };
  
  
  
  // functions
  useEffect(() => {
     onAuthStateChanged(auth, (detectedUser) => {
       if (detectedUser) {
         setAuthenticated(true);
       }else{
         setAuthenticated(false);
       };
      setTimeout(function() {
        setSplash(false);
      }, 3000);
   });   
    document.addEventListener("click", () => {
     playSound();
    });
  }, []);
  


  
  return (
    <BrowserRouter>
    {/*screens*/}
     { splash ? <Splash /> : 
        <Routes>
        
         <Route path="/"
                element={<InDevelopment />}
          />
          
         {/*<Route path="/SignIn" 
                element={
                authenticated ? 
                <Navigate to="/" /> :
                <Signin />}
          />
          
          <Route path="/referral" 
                element={ <Signin /> }
          />*/}
          
         <Route path="/TermsAndConditions" 
                element={ 
                <TermsAndConditions /> }
         />
         
         <Route path="/PrivacyPolicy" 
                element={
                <PrivacyPolicy />
                }
          />
          
         {/*<Route path="/Story"
                element={
                authenticated == false ? 
                <Navigate to="/SignIn" /> :
                <Story />} 
          />
          
         <Route path="/Ec" 
                element={
                authenticated == false ? 
                <Navigate to="/SignIn" /> :
                <Ec />} 
          />
          
         <Route path="/RankBadge"
                element={
                authenticated == false ? 
                <Navigate to="/SignIn" /> :
                <Rankbadge />} 
          />
          
         <Route path="/Game"
                element={
                authenticated == false ? 
                <Navigate to="/SignIn" /> :
                <Game />} 
         /> */}
         
         <Route path="/About"
                element={
                <About />
                } 
         />
         
         {/*<Route path="/EStore"
                element={
                authenticated == false ? 
                <Navigate to="/SignIn" />: 
                <EStore />} 
         />*/}
         
         <Route path="/InDevelopment"
                 element={
                 <InDevelopment />
                 }
         />
        </Routes>
     }
     {/*Confirm*/}
    </BrowserRouter>
  );
}

export default App;
