import React, { useState, useEffect } from "react";
import style from "./css/Toast.module.css";

const Toast = ({ msg = "Default Toast Message", color = "blue", duration = 3000, toastShow}) => {
  const [isVisible, setIsVisible] = useState(false);  

  useEffect(() => {
    setIsVisible(true);
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(() => {
        toastShow(false);
      }, 200);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <div
      className={`${style.toast} ${isVisible ? style.open : ""}`}
      style={{ backgroundColor: color }}
    >
      {msg}
    </div>
  );
};

export default Toast;