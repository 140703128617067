//imports
import React, { useState, useEffect }  from "react";
import style from "./css/InDevelopment.module.css";
import Footer from "./Footer.js"; //component
import Drower from "./Drower.js"; //component
import Loading from "./Loading.js"; //component
import Toast from "./Toast.js"; //component
import { db } from "../firebase.js";
import { onValue, get, ref as refDb } from "firebase/database"
import { useNavigate } from "react-router-dom";


function InDevelopment() {

  //variables and React hooks
  const [lang, setLang] = useState("eng");
  const [faqsOn0, setFaqsOn0] = useState(false);
  const [faqsOn1, setFaqsOn1] = useState(false);
  const [faqsOn2, setFaqsOn2] = useState(false);
  const [faqsOn3, setFaqsOn3] = useState(false);
  const [isDrowerOpen, setDrower ] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] = useState({});       
  const [loading, setLoading] = useState(false);
  const [eDB, setEDB] = useState(false);
  const [stars, setStars] = useState(0);
  const [excitements, setExcitement] = useState(0);
  const navigate = useNavigate();
  
  
  //functions
  const drowerShow = () => {
    setDrower(!isDrowerOpen);
  };
  
  const handleLang = (e) => {
    setLang(e.target.value);
  };
  
  const toastShow = (oprator) => {
    setToast(oprator);
  };
  
  useEffect(() => {
    const unsubscribe = get(refDb(db, "InDevelopmentExcitements/")).then((snapshot) => {
      if(snapshot.exists()){
        setExcitement(snapshot.val());
      };
      });
  }, []);
  
  
  
  const handleSubmit = () => {
    if(stars !== 0){
     setLoading(true);
     setTimeout(function() {
       setLoading(false);
       setEDB(false);
        setToastData({
         msg: lang == "eng" ?
              "Your excitement rating will be uploaded soon."
              :
              "आपकी एक्साइटमेंट रेटिंग जल्द ही अपलोड कर दी जाएगी।"
        });
       setToast(true);
     }, 3000);
    };
  };
  
  
  //Return
   return (
    <>
     <div id={style.bg} >
      <div id={style.header}>
       <div className={style.linear}>
       <i id={style.menuBtn} onClick={drowerShow} className="fa-solid fa-bars"></i>
       <img id={style.logo} alt="ezexa.xyz" src="./images/event.png"/>
       </div>
       <div id={style.langBtn}>
       {/*<i className="fa-solid fa-chevron-down"></i>*/}
        <select onChange={handleLang}>
         <option value="eng">English</option>
         <option value="hin">Hindi</option>
        </select>
       </div>
      </div>
      <div id={style.body}>
       <h1 id={style.heading}>Get Ready to <br/> <span>Earn</span> Like Never Before!</h1>
       
       { lang == "eng" ?
       <p id={style.paragraph}>
         E-Zexa is coming soon! Now, you can earn money by completing simple games and tasks—without any investment. That’s right, earn and win with 0 investment on E-Zexa. Stay tuned!
       </p> 
       :
       <p id={style.paragraph}>
           E-Zexa जल्द ही आ रहा है! अब, आप सरल गेम और टास्क पूरा करके पैसे कमा सकते हैं - बिना किसी निवेश के। बिलकुल सही, E-Zexa पर 0 निवेश के साथ कमाएँ और जीतें। बने रहें! 
       </p> }
       
         { lang == "eng" ? 
         <button id={style.btn}
                 onClick={() => setEDB(true)}>
           Get Excited
         </button> 
         :
         <button id={style.btn}
                 onClick={() => setEDB(true)}>
           गेट एक्साइटेड
         </button> }
         
        <img id={style.screenShots} src="./images/ezexaScreenShots.png"/>
        
        {/*Rating Box (start) */}
        <div id={style.ratingDiv}>
        { lang == "eng" ? 
        <h3> EXCITED BY REAL <span>E-ZEXA SUPPORTER</span> </h3>
        :
        <h3> एक्साइटेड बाई रियल <span>E-ZEXA सपोर्टर्स </span> </h3>
        }
         <div id={style.ratingBox}>
          <div id={style.leftDiv}>
           <div>
            <h1>4.0</h1>
           </div>
           <div>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"
               style={{color: "darkgray"}}></i>
           </div>
           <div>
            { lang == "eng" ? 
            <p>
             {excitements} EXCITEDS
            </p> 
            :
            <p>
             {excitements} एक्साइटेड
            </p> }
           </div>
          </div>
          <div id={style.rightDiv}>
          
           <div className={style.rightDivsLinear}>
            <p>5</p>
            <div className={style.progressDiv}>
             <div id={style.progress5}></div>
            </div>
            <p>84%</p>
           </div>
           
           <div className={style.rightDivsLinear}>
            <p>4</p>
            <div className={style.progressDiv}>
             <div id={style.progress4}></div>
            </div>
            <p>21%</p>
           </div>
           
           <div className={style.rightDivsLinear}>
            <p>3</p>
            <div className={style.progressDiv}>
             <div id={style.progress3}></div>
            </div>
            <p>02%</p>
           </div>
           
           <div className={style.rightDivsLinear}>
            <p>2</p>
            <div className={style.progressDiv}>
             <div id={style.progress2}></div>
            </div>
            <p>03%</p>
           </div>
           
           <div className={style.rightDivsLinear}>
            <p>1</p>
            <div className={style.progressDiv}>
             <div id={style.progress1}></div>
            </div>
            <p>10%</p>
           </div>
           
          </div>
         </div>
        </div>
        {/*Rating Box (end) */}
        
        {/*description (satrt)*/}
        { lang == "eng" ?
         <div className={style.description}>
          <p><strong>* About E-Zexa</strong><br />
          E-Zexa allows users to earn money without any investment. Users are not required to make any purchases or deposits to access the earning features of the app.
           - Users can earn money by completing tasks and playing games within the app.<br />
          - Earnings are credited in Indian Rupees (INR).<br />
          - To withdraw earnings, users must provide a valid UPI ID. E-Zexa ensures that withdrawals are processed and credited to the user's account within 24 hours.<br />
        </p>
         </div>
         :
         <div className={style.description}>
          <p><strong>* About E-Zexa</strong><br />
          E-Zexa उपयोगकर्ताओं को बिना किसी निवेश के पैसे कमाने की अनुमति देता है। ऐप की कमाई सुविधाओं तक पहुँचने के लिए उपयोगकर्ताओं को कोई खरीदारी या जमा करने की आवश्यकता नहीं है। - उपयोगकर्ता कार्य पूरा करके पैसे कमा सकते हैं। - कमाई भारतीय रुपये (INR) में जमा की जाती है।
          - कमाई निकालने के लिए, उपयोगकर्ताओं को एक वैध UPI ID प्रदान करनी होगी। E-Zexa सुनिश्चित करता है कि निकासी 24 घंटे के भीतर संसाधित हो और उपयोगकर्ता के खाते में जमा हो जाए।
        </p>
        
        
         </div> }
        {/*description (end)*/}
        
        {/*faqs (start)*/}
        { lang == "eng" ?
         <div className={style.faqsBg}>
          
          <h3>FREQUENTLY ASKED <span>QUESTIONS</span></h3>
          <div className={style.faqsDivs}>
           <div className={style.faqsHeadingsDiv}>
            <h4> When will we be able to use E-Zexa?</h4>
            <i className={ faqsOn0 ? "fa fa-chevron-up" : "fa fa-chevron-down"}
               onClick={() => setFaqsOn0(!faqsOn0)}></i>
           </div>
           <div className={style.fullFaqsAnswers}
            style={{display: faqsOn0 ? "block" : "none"}}>
            "E-Zexa" is still in development, we can guess that it may be launched in May, once it is fully developed and tested, it will be released.
           </div>
          </div>
          
          <div className={style.faqsDivs}>
           <div className={style.faqsHeadingsDiv}>
            <h4> What are the methods to Earn Real cash on E-Zexa </h4>
            <i className={ faqsOn1 ? "fa fa-chevron-up" : "fa fa-chevron-down"}
               onClick={() => setFaqsOn1(!faqsOn1)}></i>
           </div>
           <div className={style.fullFaqsAnswers}
            style={{display: faqsOn1 ? "block" : "none"}}>
            You can easily earn money by playing some simple games and completing some simple tasks in "E-Zexa".
           </div>
          </div>
          
          <div className={style.faqsDivs}>
           <div className={style.faqsHeadingsDiv}>
            <h4> How we can withdrow our cash (INR) in our Account? </h4>
            <i className={ faqsOn2 ? "fa fa-chevron-up" : "fa fa-chevron-down"}
               onClick={() => setFaqsOn2(!faqsOn2)}></i>
           </div>
           <div className={style.fullFaqsAnswers}
            style={{display: faqsOn2 ? "block" : "none"}}>
            You can withdraw your winnings or earned money in INR. For this, you must have an E-Zexa "Withdraw Card" (WC). You can provide your UPI ID and the money will be sent to you within 24 hours.
           </div>
          </div>
          
          <div className={style.faqsDivs}>
           <div className={style.faqsHeadingsDiv}>
            <h4> Is it safe and secure? </h4>
            <i className={ faqsOn3 ? "fa fa-chevron-up" : "fa fa-chevron-down"}
               onClick={() => setFaqsOn3(!faqsOn3)}></i>
           </div><div className={style.fullFaqsAnswers}
            style={{display: faqsOn3 ? "block" : "none"}}>
            "E-Zexa" is very secure and safe. If you want complete information, you can see the privacy policy.
           </div>
          </div>
          
         </div>
         
          :
          
         <div className={style.faqsBg}>
          
          <h3>अक्सर पूछे जाने वाले <span>प्रश्न</span></h3>
          <div className={style.faqsDivs}>
           <div className={style.faqsHeadingsDiv}>
            <h4>हम ई-ज़ेक्सा का उपयोग कब कर सकेंगे?</h4>
            <i className={ faqsOn0 ? "fa fa-chevron-up" : "fa fa-chevron-down"}
               onClick={() => setFaqsOn0(!faqsOn0)}></i>
           </div>
           <div className={style.fullFaqsAnswers}
            style={{display: faqsOn0 ? "block" : "none"}}>
            "E-Zexa" अभी भी विकास में है, हम अनुमान लगा सकते हैं कि इसे मई में लॉन्च किया जा सकता है, एक बार जब यह पूरी तरह से विकसित और परीक्षण हो जाएगा, तो इसे जारी किया जाएगा।
           </div>
          </div>
          
          <div className={style.faqsDivs}>
           <div className={style.faqsHeadingsDiv}>
            <h4> ई-ज़ेक्सा पर असली पैसे कमाने के तरीके क्या हैं? </h4>
            <i className={ faqsOn1 ? "fa fa-chevron-up" : "fa fa-chevron-down"}
               onClick={() => setFaqsOn1(!faqsOn1)}></i>
           </div>
           <div className={style.fullFaqsAnswers}
            style={{display: faqsOn1 ? "block" : "none"}}>
            आप "E-Zexa" में कुछ सरल गेम खेलकर और कुछ सरल कार्यों को पूरा करके आसानी से पैसा कमा सकते हैं।
           </div>
          </div> 
          
          <div className={style.faqsDivs}>
           <div className={style.faqsHeadingsDiv}>
            <h4>हम अपने खाते से नकदी (INR) कैसे निकाल सकते हैं? </h4>
            <i className={ faqsOn2 ? "fa fa-chevron-up" : "fa fa-chevron-down"}
               onClick={() => setFaqsOn2(!faqsOn2)}></i>
           </div>
           <div className={style.fullFaqsAnswers}
            style={{display: faqsOn2 ? "block" : "none"}}>
            आप अपनी जीत या अर्जित धन को INR में निकाल सकते हैं। इसके लिए आपके पास E-Zexa "विदड्रॉ कार्ड" (WC) होना चाहिए। आप अपना UPI ID प्रदान कर सकते हैं और 24 घंटे के भीतर आपको पैसे भेज दिए जाएँगे।
           </div>
          </div>
          
          <div className={style.faqsDivs}>
           <div className={style.faqsHeadingsDiv}>
            <h4> क्या यह सुरक्षित एवं संरक्षित है? </h4>
            <i className={ faqsOn3 ? "fa fa-chevron-up" : "fa fa-chevron-down"}
               onClick={() => setFaqsOn3(!faqsOn3)}></i>
           </div><div className={style.fullFaqsAnswers}
            style={{display: faqsOn3 ? "block" : "none"}}>
            "E-Zexa" बहुत सुरक्षित और सुरक्षित है। यदि आप पूरी जानकारी चाहते हैं, तो आप गोपनीयता नीति देख सकते हैं।

           </div>
          </div>
          
         </div> }
        {/*faqs (start)*/}
        
        {/*contact (start)*/}
         <div className={style.description}style={{display: lang == "eng" ? "block" : "none"}}>
          <strong>* Contact Us</strong>
            <p>If you have any questions, please contact us at:</p>
            <p><strong>Email:</strong> ezexahelp@gmail.com</p>
            <p><strong>WhatsApp:</strong> +91 8423409131</p>
         </div>
         <div className={style.description}style={{display: lang == "hin" ? "block" : "none"}}>
          <strong>* Contact Us</strong>
            <p>यदि आपके कोई प्रश्न हों तो कृपया हमसे संपर्क करें:</p>
            <p><strong>Email:</strong> ezexahelp@gmail.com</p>
            <p><strong>WhatsApp:</strong> +91 8423409131</p>
         </div>
        {/*contact (end)*/}
        
        
        <Footer />
      </div>
     </div>
     
     {/*drower (satrt)*/}
     { isDrowerOpen && 
      <div id={style.drower}>
       <div id={style.drowerBox}>
        <div id={style.drowerHeader}>
         <i className="fa fa-xmark"
          onClick={() => setDrower(false)}></i>
        </div>
        <h3 onClick={() => navigate("/About")}>About Us</h3>
        <h3 onClick={() => navigate("/TermsAndConditions")}>Terms And Conditions</h3>
        <h3 onClick={() => navigate("/PrivacyPolicy")}>Privacy Policy</h3>
       </div>
      </div>
     }
     {/*drower (end)*/}
     
     {/*Excited Bottom Drower (start) */}
     { eDB && <div id={style.excitedBottomDrower}>
      <div id={style.eDBBox}>
       <div id={style.eDBHeader}>
        <div></div> <i className="fa fa-xmark"
          onClick={() => setEDB(false)}></i>
       </div>
       <div id={style.eDBBody}>
       { lang == "eng" ? 
       <h3>Your excitement Matters us</h3>
       :
       <h3>आपका एक्साइटमेंट हमारे लिए मायने रखता है</h3>}
       { lang == "eng" ?
        <p>Your excitement will keep us more motivated toward our platform and encourage us to develop it quickly.
        </p>
        :
        <p>आपका एक्साइटमेंट हमें अपने प्लेटफॉर्म के प्रति और अधिक प्रेरित रखेगा तथा इसे शीघ्रता से विकसित करने के लिए प्रोत्साहित करेगा।</p>}
        
       <div id={style.eDBStarsDiv}>
        <i className="fa fa-star"
           onClick={() => setStars(1)}
           style={{color: 
                  (stars == 1 ||
                   stars == 2  ||
                   stars == 3  ||
                   stars == 4  ||
                   stars == 5 ) ? "#f40057" :
                   "lightgray"}}></i>
        <i className="fa fa-star"
           onClick={() => setStars(2)}
           style={{color: 
                  (stars == 2  ||
                   stars == 3  ||
                   stars == 4  ||
                   stars == 5 ) ? "#f40057" :
                   "lightgray"}}></i>
        <i className="fa fa-star"
           onClick={() => setStars(3)}
           style={{color: 
                  (stars == 3  ||
                   stars == 4  ||
                   stars == 5 ) ? "#f40057" :
                   "lightgray"}}></i>
        <i className="fa fa-star"
           onClick={() => setStars(4)}
           style={{color: 
                  (stars == 4  ||
                   stars == 5 ) ? "#f40057" :
                   "lightgray"}}></i>
        <i className="fa fa-star"
           onClick={() => setStars(5)}
           style={{color: 
                  (stars == 5 ) ? "#f40057" :
                   "lightgray"}}></i>
       </div>
       <button onClick={handleSubmit}>{ lang == "eng" ? "Submit" : "सब्मिट करें " }</button>
       </div>
      </div>
     </div> }
     {/*Excited Bottom Drower (start) */}
     
     
     { loading && <Loading /> }
     { toast && <Toast msg={toastData.msg} toastShow={toastShow} /> }
     </>
    );
 };


export default InDevelopment;
