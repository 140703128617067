// imports
import React from "react";
import style from "./css/Footer.module.css";
import { useNavigate } from "react-router-dom";

function Footer(){
  const navigate = useNavigate();
  
  const goToTAC = () => {
    navigate("/TermsAndConditions");
  };
  const goToAU = () => {
    navigate("/About");
  };
  const goToPP = () => {
    navigate("/PrivacyPolicy");
  };
  
  return(
  <>
    <div id={style.bg}>
      <div
       id={style.firstLinear}>
        <p onClick={goToTAC}>Terms  &  Conditions</p>|
        <p onClick={goToPP}> Privacy Policy </p>|
        <p onClick={goToAU}> About Us </p></div>
      <div>
      <p> Copyright &copy; 2024 | All Rights Reserved | E-Zexa </p></div>
      <div id={style.imgLinear}>
       <img src="./images/footer_img1.png" />
       <img src="./images/footer_img2.png" />
       <img src="./images/footer_img3.png" />
      </div>
    </div>
  </>
  );
};

export default Footer;
