//imports
import React from "react";
import style from "./css/Splash.module.css";
import Footer from "./Footer.js"; //Components

function Splash(props){
  return (
    <>
      <div id={style.bg}>
      <div id={style.body}>
       <img id={style.logo} src="/images/circleLogo.png" alt="E-Zexa App logo" />
       </div>
       <div id={style.loadingDiv}>
       <div id={style.loader}>
       
       </div>
       <p>Loading...</p></div>
      </div>
    </>
    );
};
export default Splash;