// imports
import React, { useState, useEffect, useRef } from "react";
import style from "./css/Dashboard.module.css";
import { auth, db } from '../firebase';
import Home from "./Home.js"; //Components(page)
import Notification from "./Notification.js"; //Components(page)
import Leaderboard from "./Leaderboard.js"; //Components(page)
import Refer from "./Refer.js"; //Components(page)
import Settings from "./Settings.js";
import Navbar from "./Navbar.js"; //Components
import Confirm from "./Confirm.js"; //components

function Dashboard(props){
  //variables and react hooks
  const [curNav, setCurNav] = useState("Home");
  const [confirm, setConfirm] = useState(false);
  const [confirmVal, setConfirmVal] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  
  //function
  const goToNav = (naveName) => {
    setCurNav(naveName);
  }
  //
  useEffect(() => {
    if(confirmVal){
      setConfirmVal(false);
    };
  }, [confirmVal])
  
  return(
    <>
    <div id={style.bg}>
     <div id={style.pages}>
      { curNav == "Home" && <Home goToNav={goToNav}/>}
      { curNav == "Notification" && <Notification goToNav={goToNav}/>}
      { curNav == "Leaderboard" && <Leaderboard goToNav={goToNav}/>}
      { curNav == "Refer" && <Refer goToNav={goToNav}/>}
      { curNav == "Settings" && <Settings goToNav={goToNav}/>}
     </div>
     <div id={style.navbar}>
     <Navbar curNav={curNav} goToNav={goToNav} />
     </div>
    </div>
    { confirm && <Confirm 
      confirm={setConfirm} 
      confirmVal={setConfirmVal}
      title={confirmData.title}
      msg={confirmData.msg}/> }
    </>
    );
};
export default Dashboard;